import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { saveSingle } from "../../services/contactService";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import { Table } from "semantic-ui-react";
import TableTitle from "../common/tableTitle";
import Section from "../common/section";
import { Link } from "react-router-dom";

class ContactForm extends Form {
  state = {
    data: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    errors: {},
  };

  schema = {
    id: Joi.string(),
    name: Joi.string().required().label("Name"),
    email: Joi.string().required().label("Email"),
    subject: Joi.string().required().label("Subject"),
    message: Joi.string().required().label("Message"),
  };

  doSubmit = async () => {
    try {
      this.setState({ blocking: true });
      await saveSingle(this.state.data);
      toast.success("Contact has been added successfully.");
      this.setState({ blocking: false });
      this.props.history.push("/web/contacts");
    } catch (ex) {
      console.log(ex.response);
      if (ex.response && ex.response.status === 422) {
        const errors = { ...this.state.errors };

        if (ex.response.data.errors.name)
          errors.name = ex.response.data.errors.name;

        if (ex.response.data.errors.address)
          errors.address = ex.response.data.errors.address;

        if (ex.response.data.errors.phone)
          errors.phone = ex.response.data.errors.phone;

        if (ex.response.data.errors.account)
          errors.account = ex.response.data.errors.account;

        this.setState({ errors, blocking: false });
        //toast.warning("check validation errors.");
      }
    }
  };

  render() {
    const dashboardIcon = process.env.REACT_APP_URL + "/new_theme/bank.png";
    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <Section module="Add Query" description="Add new contact query" />

        <form onSubmit={this.handleSubmit} class="card">
          <div className="grid grid--5x2">
            <span>Name</span>
            {this.renderInput("name", "Name", "text")}
            <span>Email</span>
            {this.renderInput("email", "Email", "text")}
            <span>Subject</span>
            {this.renderInput("subject", "Subject", "text")}
            <span>Message</span>
            {this.renderTextarea("message", "Message", "text")}

            <div></div>
            <div className="grid__buttons">
              {this.renderButton("Save")}&nbsp;
              <Link to="/web/contacts" className="btn btn--secondary">
                Back
              </Link>
            </div>
          </div>
        </form>
      </BlockUi>
    );
  }
}

export default ContactForm;
