import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { saveSingle } from "../../services/newsService";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import { Table } from "semantic-ui-react";
import TableTitle from "../common/tableTitle";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertToHTML, convertFromHTML } from "draft-convert";

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import Section from "../common/section";

class NewsForm extends Form {
  state = {
    preferredDate: new Date(),
    thumbUrl: "",
    imageUrl: "",

    data: {
      title: "",
      short: "",
      author: "",
    },
    editorState: EditorState.createEmpty(),
    errors: {},
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  schema = {
    id: Joi.string(),
    title: Joi.string().required().label("Title"),
    short: Joi.string().required().label("Short"),
    author: Joi.string().required().label("Author"),
  };

  doSubmit = async () => {
    try {
      this.setState({ blocking: true });

      const text = convertToHTML(this.state.editorState.getCurrentContent());

      let formData = new FormData();
      formData.append(
        "category",
        this.state.data.category == "" ? "default" : this.state.data.category
      );
      formData.append(
        "location",
        this.state.data.location == "" ? "default" : this.state.data.location
      );
      formData.append("title", this.state.data.title);
      formData.append("short", this.state.data.short);
      formData.append("description", text);
      formData.append("author", this.state.data.author);
      formData.append("thumbnail", this.state.thumbUrl);
      formData.append("image", this.state.imageUrl);

      var event = new Date(this.state.preferredDate);
      let date = JSON.stringify(event);
      date = date.slice(1, 11);
      formData.append("news_date", date);

      await saveSingle(formData);

      toast.success("News has been added successfully.");
      this.setState({ blocking: false });
      this.props.history.push("/web/news");
    } catch (ex) {
      console.log(ex.response);
      if (ex.response && ex.response.status === 422) {
        const errors = { ...this.state.errors };

        if (ex.response.data.errors.name)
          errors.name = ex.response.data.errors.name;

        if (ex.response.data.errors.address)
          errors.address = ex.response.data.errors.address;

        if (ex.response.data.errors.phone)
          errors.phone = ex.response.data.errors.phone;

        if (ex.response.data.errors.account)
          errors.account = ex.response.data.errors.account;

        this.setState({ errors, blocking: false });
        //toast.warning("check validation errors.");
      }
    }
  };

  handleChangePreferredDate = (date) => {
    this.setState({
      preferredDate: date,
    });
  };

  onChangeThumbnail = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.setState({ thumbUrl: files[0] });
    //this.createImage(files[0]);
  };

  onChangeImage = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.setState({ imageUrl: files[0] });
    //this.createImage(files[0]);
  };

  render() {
    const dashboardIcon = process.env.REACT_APP_URL + "/new_theme/bank.png";
    const { editorState } = this.state;
    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <Section module="Add News" description="Add new news" />

        <form onSubmit={this.handleSubmit} class="card">
          <div className="grid grid--8x2">
            <span>Author</span>
            {this.renderInput("author", "Author", "text")}
            <span>Title</span>
            {this.renderInput("title", "Title", "text")}
            <span>Short</span>
            {this.renderTextarea("short", "Short", "text")}
            <span>Date (dd/mm/year)</span>
            <DatePicker
              selected={this.state.preferredDate}
              onChange={this.handleChangePreferredDate}
            />

            <span>Thumbnail</span>
            <input
              className="input_imagem_artigo"
              type="file"
              onChange={this.onChangeThumbnail}
            />
            <span>Image</span>
            <input
              className="input_imagem_artigo"
              type="file"
              onChange={this.onChangeImage}
            />
            <span>Detail</span>
            <Editor
              initialEditorState={editorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              editorStyle={{
                border: "1px solid #eeeeee",
                padding: "7px",
                paddingTop: "0px",
              }}
              toolbarStyle={{}}
              editorState={editorState}
              onEditorStateChange={this.onEditorStateChange}
            />
            <div></div>
            <div className="grid__buttons">
              {this.renderButton("Save")}&nbsp;
              <Link to="/web/news" className="btn btn--secondary">
                Back
              </Link>
            </div>
          </div>
        </form>
      </BlockUi>
    );
  }
}

export default NewsForm;
