import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import auth from "../services/authService";
import { Redirect } from "react-router-dom";
import BlockUi from "react-block-ui";
import { Table, Grid } from "semantic-ui-react";

class LoginForm extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
  };

  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  componentDidMount() {
    // document.body.style.backgroundImage = "url('./new_theme/accounting2.jpg')";
  }

  doSubmit = async () => {
    try {
      this.state.blocking = true;
      const { data } = this.state;

      await auth.login(data.username, data.password);
      this.state.blocking = false;

      window.location = "/dashboard";
    } catch (ex) {
      this.state.blocking = false;
      if (ex.response && ex.response.status === 401) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data.error;
        this.setState({ errors });
      }
    }
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/dashboard" />;
    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <center>
          <form onSubmit={this.handleSubmit}>
            <div className="card grid grid--1x1 block-login">
              <img src="logo.png" className="block-login__image" />
              {this.renderInput("username", "", "text")}
              {this.renderInput("password", "", "password")}
              {this.renderButton("Login")}
              <p>Powered By VAST Technology Solutions.</p>
            </div>
          </form>
        </center>
      </BlockUi>
    );
  }
}
export default LoginForm;
