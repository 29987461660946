import React, { Component } from "react";

class TopStatus extends Component {
  render() {
    const { count, module } = this.props;
    return (
      <p>
        showing total <b>{count}</b> {module}.
      </p>
    );
  }
}

export default TopStatus;
