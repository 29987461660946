import React from "react";
import { Table, Input } from "semantic-ui-react";
const SearchTextBox = (props) => {
  const { onSearchButtonClick } = props;

  return (
    <React.Fragment>
      <div className="input-group">
        <input
          id="keyword"
          type="text"
          className="input"
          placeholder="Search by keyword..."
        />
        <button
          id="searcBtn"
          onClick={() =>
            onSearchButtonClick(document.getElementById("keyword").value)
          }
          className="btn btn--accent"
        >
          Search
        </button>
      </div>
      <button
        onClick={function () {
          document.getElementById("keyword").value = "";
          document.getElementById("searcBtn").click();
        }}
        className="btn btn--secondary"
        type="button"
      >
        Reset
      </button>
    </React.Fragment>
  );
};

export default SearchTextBox;
