import http from "./httpService";
import auth from "./authService";

export function generateSalaryReport(data) {
  return http.post(`/v1/reports/salary`, data);
}

export function generateProjectSalaryReport(data) {
  return http.post(`/v1/reports/project-salary`, data);
}

export function generateIncomeReport(data) {
  return http.post(`/v1/reports/income`, data);
}

export function generateScrapReport(data) {
  return http.post(`/v1/reports/scrap`, data);
}

export function doGenerateClosingBalance(data) {
  return http.post(`/v1/reports/scrap/generate-balances`, data);
}

export function doGenerateJVClosingBalance(data) {
  return http.post(`/v1/reports/jv/generate-balances`, data);
}

export function generateJVReport(data) {
  return http.post(`/v1/reports/jv`, data);
}

export function generateVehicleReport() {
  return http.get(`/v1/reports/vehicle`);
}

export function generateVisaReport() {
  return http.get(`/v1/reports/visa`);
}
export function generateYardReport() {
  return http.get(`/v1/reports/yard`);
}

export function generateYardMonthlyEntries() {
  return http.get(`/v1/reports/yard/monthly-entries`);
}

export function generateRoomReport() {
  return http.get(`/v1/reports/room`);
}

export function generateRoomMonthlyEntries() {
  return http.get(`/v1/reports/room/monthly-entries`);
}

export function generateGeneralLedger(data) {
  return http.post(`/v1/reports/general-ledger`, data);
}
export function getGeneralReport(companyId) {
  return http.get(`/v1/companies/${companyId}/reports/general`);
}

export function getOverAllReport(data) {
  return http.post(`/v1/reports/overall`, data);
}

export function generateSalary(data) {
  return http.post(`/v1/reports/generate-salary`, data);
}

export function generateReceiveableReport(data) {
  return http.post(`/v1/reports/generate-receiveable-report`, data);
}

export function generatePayableReport(data) {
  return http.post(`/v1/reports/generate-payable-report`, data);
}

export function getVehicleExpiryReport(data) {
  return http.post(`/v1/reports/vehicle-expiry`, data);
}

export function getDocumentsExpiryReport(data) {
  return http.post(`/v1/reports/documents-expiry`, data);
}

export function getVisaExpiryReport(data) {
  return http.post(`/v1/reports/visa-expiry`, data);
}

export function getPassportExpiryReport(data) {
  return http.post(`/v1/reports/passport-expiry`, data);
}

export function getPassportInOutReport(data) {
  return http.post(`/v1/reports/passport-inout`, data);
}

export function getInvoiceReport(data) {
  return http.post(`/v1/reports/invoices`, data);
}

export function getProjectReport(data) {
  return http.post(`/v1/reports/projects`, data);
}

export function getPDCPaidReport(data) {
  return http.post(`/v1/reports/pdc-paid`, data);
}

export function getPDCReceivedReport(data) {
  return http.post(`/v1/reports/pdc-received`, data);
}
