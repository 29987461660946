import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { saveSingle } from "../../services/photoService";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import { Table } from "semantic-ui-react";
import TableTitle from "../common/tableTitle";
import { Link } from "react-router-dom";
import Section from "../common/section";

class PhotoForm extends Form {
  state = {
    imageUrl: "",
    data: {
      name: "",
      resource_id: "",
    },
    errors: {},
    resources: [
      { id: "", name: "Select Resource" },
      { id: 1, name: "Forms" },
      { id: 2, name: "Posters" },
      { id: 3, name: "Meal Pattrens" },
      { id: 4, name: "Tools" },
      { id: 5, name: "Miscellaneous" },
    ],
  };

  schema = {
    id: Joi.string(),
    name: Joi.string().required().label("Name"),
    resource_id: Joi.string().required().label("Resource"),
  };

  doSubmit = async () => {
    try {
      this.setState({ blocking: true });

      let formData = new FormData();
      formData.append("name", this.state.data.name);
      formData.append("resource_id", this.state.data.resource_id);
      formData.append("image", this.state.imageUrl);

      await saveSingle(formData);

      toast.success("Photo has been added successfully.");
      this.setState({ blocking: false });
      this.props.history.push("/web/photos");
    } catch (ex) {
      console.log(ex.response);
      if (ex.response && ex.response.status === 422) {
        const errors = { ...this.state.errors };

        if (ex.response.data.errors.name)
          errors.name = ex.response.data.errors.name;

        this.setState({ errors, blocking: false });
        //toast.warning("check validation errors.");
      }
    }
  };

  onChangeImage = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.setState({ imageUrl: files[0] });
    //this.createImage(files[0]);
  };

  render() {
    const dashboardIcon = process.env.REACT_APP_URL + "/new_theme/bank.png";
    const { editorState } = this.state;
    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <Section module="Add Resource" description="Add new resource." />

        <form onSubmit={this.handleSubmit} class="card">
          <div className="grid grid--4x2">
            <span>Resource</span>
            {this.renderSelect("resource_id", "Resource", this.state.resources)}
            <span>Name</span>
            {this.renderInput("name", "Name", "text")}
            <span>Image</span>
            <input
              className="input_imagem_artigo"
              type="file"
              onChange={this.onChangeImage}
            />
            <div></div>
            <div className="grid__buttons">
              {this.renderButton("Save")}&nbsp;
              <Link to="/web/photos" className="btn btn--secondary">
                Back
              </Link>
            </div>
          </div>
        </form>
      </BlockUi>
    );
  }
}

export default PhotoForm;
