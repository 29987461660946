import React, { Component } from "react";
import { getAll, deleteSingle } from "../../services/newsService";
import { paginate } from "../../utils/paginate";
import SearchTextBox from "../common/searchTextBox";
import NewsTable from "./table";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import _ from "lodash";
import TableTitle from "../common/tableTitle";
import { Table, Confirm } from "semantic-ui-react";
import TopStatus from "../common/topStatus";
import { Pagination } from "antd";

class News extends Component {
  state = {
    open: false,
    type: "",
    types: [],

    sortColumn: { path: "title", order: "asc" },
    noRecordFound: "",

    pageSize: 10,
    currentPage: 1,
    keyFieldValue: "",
    totalRecords: "",
  };

  async componentDidMount() {
    document.body.style.backgroundImage = "url('./new_theme/bank-bg.jpg')";

    this.setState({ blocking: true });
    const { data: types } = await getAll();

    this.setState({
      types: types.entries,
      totalRecords: types.total,
      blocking: false,
    });
  }

  handleDelete = (type) => {
    this.setState({ open: true, type });
  };

  doDelete = async () => {
    const type = this.state.type;
    const originalTypes = this.state.types;
    const types = originalTypes.filter((o) => o.id !== type.id);
    this.setState({ types, open: false });

    try {
      await deleteSingle(type.id);
      toast.success("News has been deleted successfully.");
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        toast.success("This News has already been deleted.");
      }
      this.setState({ types: originalTypes });
    }
  };

  handleUpdate = (type) => {
    return this.props.history.replace("/web/news/" + type.id + "/edit");
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  handleSearching = (keyword) => {
    this.setState({ keyFieldValue: keyword, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleSearching = (keyword) => {
    this.setState({
      keyFieldValue: keyword,
      currentPage: 1,
    });

    let page = 1;

    this.props.history.replace("/web/news?page=" + page);
    this.getData(page, keyword);
  };

  handlePagination(page, pageSize) {
    this.setState({ currentPage: page });
    this.props.history.replace("/web/news?page=" + page);
    this.getData(page);
  }

  getData = async (page, keyword = "") => {
    this.setState({ blocking: true });
    const { data: types } = await getAll(page, keyword);

    this.setState({
      types: types.entries,
      totalRecords: types.total,
      blocking: false,
    });
  };

  render() {
    const dashboardIcon = process.env.REACT_APP_URL + "/new_theme/bank.png";
    const emtpyUrl = process.env.REACT_APP_URL + "/empty1.png";
    const { pageSize, currentPage, sortColumn, show, totalRecords, types } =
      this.state;

    const otherShow = show === "none" ? "block" : "none";

    if (totalRecords === 0) {
      return (
        <BlockUi
          tag="div"
          blocking={this.state.blocking}
          style={{ display: this.state.blocking ? "none" : "block" }}
        >
          <div>
            <center>
              <img src={emtpyUrl} />
              <div>
                <p>News not added yet.</p>
                <Link to="/web/news/new" className="btn btn--primary">
                  + News
                </Link>
              </div>
            </center>
          </div>
        </BlockUi>
      );
    }

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <section className="block container block-plans search-block">
          <header class="block__header">
            <h2>News</h2>
            <p>Manage your news.</p>
          </header>

          <div className="grid grid--1x3">
            <SearchTextBox onSearchButtonClick={this.handleSearching} />
            <Link to="/web/news/new" className="btn btn--primary">
              + News
            </Link>
          </div>
        </section>

        <Confirm
          open={this.state.open}
          header="Confirmation"
          content="Are you sure, you want to delete the news?"
          onCancel={this.handleCancel}
          onConfirm={this.doDelete}
          size="mini"
        />

        <center>
          <div className="outer-container">
            <div
              className="ps-pagination"
              style={{ display: totalRecords < pageSize ? "none" : "block" }}
            >
              <Pagination
                total={totalRecords}
                pageSize={pageSize}
                responsive={true}
                showSizeChanger={false}
                current={currentPage !== undefined ? parseInt(currentPage) : 1}
                onChange={(e) => this.handlePagination(e)}
              />
            </div>

            <TopStatus count={types.length} module="news" />

            <NewsTable
              types={types}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onUpdate={this.handleUpdate}
              onSort={this.handleSort}
              onStatusUpdate={this.handleUpdateStatus}
            />
          </div>
        </center>
      </BlockUi>
    );
  }
}

export default News;
