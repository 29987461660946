import React, { Component } from "react";
import Dashboard from "./components/dashboard";
import { Route, Redirect, Switch } from "react-router-dom";
import NotFound from "./components/notfound";

import Navigation from "./components/common/navigation";
import LoginForm from "./components/loginForm";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "react-block-ui/style.css";
import Logout from "./components/logout";
import auth from "./services/authService";
import ProtectedRoute from "./components/common/protectedRoute";
import { CommentActions, Container } from "semantic-ui-react";

// Web Part
import Contacts from "./components/web_contact/contacts";
import ContactForm from "./components/web_contact/form";
import ContactEditForm from "./components/web_contact/editForm";

// News Part
import News from "./components/web_news/news";
import NewsForm from "./components/web_news/form";
import NewsEditForm from "./components/web_news/editForm";

// Photos Part
import Photos from "./components/web_photo/photos";
import PhotoForm from "./components/web_photo/form";
import PhotoEditForm from "./components/web_photo/editForm";

class App extends Component {
  state = {};
  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }
  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        {user && <Navigation user={user} />}
        <ToastContainer />

        <Container style={{ marginTop: "1rem", width: "96%" }}>
          <Switch>
            <ProtectedRoute
              path="/dashboard/:outlet"
              exact
              component={Dashboard}
            />
            <ProtectedRoute path="/dashboard" component={Dashboard} />

            {/* contacts */}
            <ProtectedRoute
              exact
              path="/web/contacts/:id"
              component={ContactForm}
            />
            <ProtectedRoute
              path="/web/contacts/:id/edit"
              component={ContactEditForm}
            />
            <ProtectedRoute path="/web/contacts" component={Contacts} />

            {/* news */}
            <ProtectedRoute exact path="/web/news/:id" component={NewsForm} />
            <ProtectedRoute
              path="/web/news/:id/edit"
              component={NewsEditForm}
            />
            <ProtectedRoute path="/web/news" component={News} />

            {/* photos */}
            <ProtectedRoute exact path="/web/photo/:id" component={PhotoForm} />
            <ProtectedRoute
              path="/web/photo/:id/edit"
              component={PhotoEditForm}
            />
            <ProtectedRoute path="/web/photos" component={Photos} />
            <Route path="/login" component={LoginForm} />
            <Route path="/logout" component={Logout} />
            <Route path="/not-found" component={NotFound} />
          </Switch>
        </Container>
      </React.Fragment>
    );
  }
}

export default App;
