import http from "./httpService";
import auth from "./authService";

const headers = {
  headers: {
    "Content-Type": "multipart/form-data"
  }
};


function supplierStoreUrl(id) { 
  if (id)
    return `/v2/companies/${auth.getCurrentUser().company_id}/news/${id}`;
  else return `/v2/companies/${auth.getCurrentUser().company_id}/news`;
}

export function getAll(page=1,keyword="") {
  return http.get(`/v2/companies/${auth.getCurrentUser().company_id}/news?page=${page}&keyword=${keyword}`);
}

export function deleteSingle(supplierId) {
  return http.delete(supplierStoreUrl(supplierId));
}

export function saveSingle(supplier) {
  return http.post(supplierStoreUrl(), supplier, headers);
}

export function updateSingle(supplier, ledgerTypeId) {
  return http.post(supplierStoreUrl(ledgerTypeId), supplier, headers);
}

export function getSingle(supplierId) {
  return http.get(supplierStoreUrl(supplierId));
}
