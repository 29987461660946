import React, { Component } from "react";
import RenderTable from "../common/table";
import { Icon } from "semantic-ui-react";
import auth from "../../services/authService";

class NewsTable extends Component {
  columns = [
    {
      path: "title",
      label: "Title",
      width: "5",
    },
    {
      path: "short",
      label: "Short",
      width: "5",
    },
    {
      path: "author",
      label: "Author",
      width: "2",
    },
    {
      path: "news_date",
      label: "Date",
      width: "2",
    },
    {
      key: "actions",
      width: "2",
      content: (type) => (
        //auth.getCurrentUser().email === "company-unblocker@gondalgroup.ae" && (
        <div>
          <a
            className="badge badge--accent badge--small"
            href="#"
            onClick={() => this.props.onDelete(type)}
          >
            Delete
          </a>
          &nbsp;
          <a
            href="#"
            className="badge badge--accent badge--small"
            onClick={() => this.props.onUpdate(type)}
          >
            Edit
          </a>
        </div>
      ),
      // )
    },
  ];

  render() {
    const { types, onSort, sortColumn } = this.props;

    return (
      <RenderTable
        columns={this.columns}
        data={types}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default NewsTable;
