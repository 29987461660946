import React, { Component } from "react";
import { Statistic, Grid, Message } from "semantic-ui-react";
import { getGeneralReport, getOverAllReport } from "../services/reportService";
import BlockUi from "react-block-ui";
import TableTitle from "./common/tableTitle";
import auth from "../services/authService";
import { Link } from "react-router-dom";

class Dashboard extends Component {
  state = {
    report: { outlet_account: [], companyExpiryCounter: [] },
  };

  loadDashboard = async (companyId) => {
    this.setState({
      blocking: true,
    });
    const { data: report } = await getGeneralReport(companyId);

    this.setState({ report, blocking: false });
  };

  async componentDidMount() {
    //document.body.style.backgroundImage = "url('./new_theme/dashboard-bg.png')";
    this.loadDashboard(auth.getCurrentUser().company_id);
  }

  render() {
    const dashboardIcon =
      process.env.REACT_APP_URL + "/new_theme/dashboard.png";

    let { report } = this.state;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <section className="block container block-plans dashboard">
          <header class="block__header">
            <h2>Dashboard</h2>
            <p>Manage news, resources and contact queries.</p>
          </header>
          <div className="grid grid--1x3">
            <div className="plan">
              <div className="card card--secondary">
                <header className="card__header">
                  <h3 className="plan__name">News</h3>
                  <span className="plan__price">
                    {report.company_web_news_count}
                  </span>
                  <span className="plan__billing-cycle">/total</span>

                  <span className="plan__description">Manage your news</span>
                </header>
                <div className="card__body">
                  <Link className="btn btn--outline btn--block" to="/web/news">
                    Manage
                  </Link>
                </div>
              </div>
            </div>
            <div className="plan">
              <div className="card card--primary">
                <header className="card__header">
                  <h3 className="plan__name">Resources</h3>
                  <span className="plan__price">
                    {report.company_web_photos_count}
                  </span>
                  <span className="plan__billing-cycle">/total</span>

                  <span className="plan__description">
                    Manage your resources
                  </span>
                </header>
                <div className="card__body">
                  <Link
                    className="btn btn--outline btn--block"
                    to="/web/photos"
                  >
                    Manage
                  </Link>
                </div>
              </div>
            </div>
            <div className="plan">
              <div className="card card--secondary">
                <header className="card__header">
                  <h3 className="plan__name">Queries</h3>
                  <span className="plan__price">
                    {report.company_web_contact_count}
                  </span>
                  <span className="plan__billing-cycle">/total</span>

                  <span className="plan__description">Manage your queries</span>
                </header>
                <div className="card__body">
                  <Link
                    className="btn btn--outline btn--block"
                    to="/web/contacts"
                  >
                    Manage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Message>
                  <Statistic.Group widths="1">
                    <Statistic color="orange">
                      <Statistic.Value>
                        {report.company_web_contact_count}
                      </Statistic.Value>
                      <Statistic.Label>Contacts</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </Message>
              </Grid.Column>

              <Grid.Column width={4}>
                <Message>
                  <Statistic.Group widths="1">
                    <Statistic color="yellow">
                      <Statistic.Value>
                        {report.company_web_news_count}
                      </Statistic.Value>
                      <Statistic.Label>News</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </Message>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <Message>
                  <Statistic.Group widths="1">
                    <Statistic color="blue">
                      <Statistic.Value>
                        {report.company_web_photos_count}
                      </Statistic.Value>
                      <Statistic.Label>Photos</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </Message>
              </Grid.Column>
              <Grid.Column width={4}></Grid.Column>
              <Grid.Column width={4}></Grid.Column>
            </Grid.Row>
          </Grid>
        </div> */}
      </BlockUi>
    );
  }
}

export default Dashboard;
