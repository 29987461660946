import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getSingle, updateSingle } from "../../services/photoService";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import TableTitle from "../common/tableTitle";
import { Table } from "semantic-ui-react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Section from "../common/section";

class PhotoEditForm extends Form {
  state = {
    imageUrl: "",
    data: {
      name: "",
      resource_id: "",
    },

    errors: {},
    resources: [
      { id: 1, name: "Forms" },
      { id: 2, name: "Posters" },
      { id: 3, name: "Meal Pattrens" },
      { id: 4, name: "Tools" },
      { id: 5, name: "Miscellaneous" },
    ],
  };

  schema = {
    name: Joi.string().required().label("Name"),
    resource_id: Joi.number().required().label("Resource"),
  };

  onChangeImage = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.setState({ imageUrl: files[0] });
    //this.createImage(files[0]);
  };

  async populateAccount() {
    try {
      const accountId = this.props.match.params.id;

      this.setState({ blocking: true });
      const { data: account } = await getSingle(accountId);

      this.setState({ blocking: false });

      this.mapingImagesData(account);

      this.setState({
        imageUrl: account.image,
      });

      this.setState({ data: this.mapToViewModel(account) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateAccount();
  }

  mapingImagesData(account) {
    return {
      imageUrl: account.image,
    };
  }

  mapToViewModel(account) {
    return {
      name: account.name,
      resource_id: account.resource_id,
    };
  }

  doSubmit = async () => {
    try {
      const ledgerTypeId = this.props.match.params.id;

      let formData = new FormData();
      formData.append("name", this.state.data.name);
      formData.append("resource_id", this.state.data.resource_id);
      formData.append("image", this.state.imageUrl);

      formData.append("_method", "PUT");

      await updateSingle(formData, ledgerTypeId);
      this.setState({ blocking: false });
      toast.success("Photo has been updated successfully.");
      this.props.history.push("/web/photos");
    } catch (ex) {
      console.log(ex.response);
      if (ex.response && ex.response.status === 422) {
        const errors = { ...this.state.errors };

        if (ex.response.data.errors.name)
          errors.name = ex.response.data.errors.name;

        this.setState({ errors, blocking: false });
        // toast.warning("check validation errors.");
      }
    }
  };

  render() {
    const dashboardIcon = process.env.REACT_APP_URL + "/new_theme/bank.png";
    const { editorState } = this.state;
    // if (auth.getCurrentUser().email != "company-unblocker@gondalgroup.ae") {
    //   return "Your are authorized to perform this action.";
    // }
    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <Section module="Update Resource" description="Update a resource." />

        <form onSubmit={this.handleSubmit} class="card">
          <div className="grid grid--4x2">
            <span>Resource</span>
            {this.renderSelect("resource_id", "Resource", this.state.resources)}
            <span>Name</span>
            {this.renderInput("name", "Name", "text")}
            <span>Image</span>
            <input
              className="input_imagem_artigo"
              type="file"
              onChange={this.onChangeImage}
            />
            <div></div>
            <div className="grid__buttons">
              {this.renderButton("Update")}&nbsp;
              <Link to="/web/photos" className="btn btn--secondary">
                Back
              </Link>
            </div>
          </div>
        </form>
      </BlockUi>
    );
  }
}

export default PhotoEditForm;
