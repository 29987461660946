import http from "./httpService";
import auth from "./authService";

function supplierStoreUrl(id) {
  
  if (id)
    return `/v2/companies/${auth.getCurrentUser().company_id}/contacts/${id}`;
  else return `/v2/companies/${auth.getCurrentUser().company_id}/contacts`;
}

export function getAll(page=1,keyword="") {
  return http.get(`/v2/companies/${auth.getCurrentUser().company_id}/contacts?page=${page}&keyword=${keyword}`);
}

export function deleteSingle(supplierId) {
  return http.delete(supplierStoreUrl(supplierId));
}

export function saveSingle(supplier) {
  return http.post(supplierStoreUrl(), supplier);
}

export function updateSingle(supplier) {
  return http.put(supplierStoreUrl(supplier.id), supplier);
}

export function getSingle(supplierId) {
  return http.get(supplierStoreUrl(supplierId));
}
