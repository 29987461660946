import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Navigation extends Component {
  componentDidMount() {
    const collapsibles = document.querySelectorAll(".collapsible");
    collapsibles.forEach((item) =>
      item.addEventListener("click", function () {
        this.classList.toggle("collapsible--expanded");
      })
    );
  }

  render() {
    const logoUrl = process.env.REACT_APP_URL + "/logo.png";
    const spriteUrl = process.env.REACT_APP_URL + "/sprite.svg#menu";

    return (
      <nav className="nav collapsible">
        <a className="nav__brand" href="/dashboard">
          <img height="40" src={logoUrl} alt="" />
        </a>
        <svg className="icon icon--white nav__toggler">
          <use xlinkHref={spriteUrl} />
        </svg>
        <ul className="list nav__list collapsible__content">
          <li className="nav__item">
            <NavLink to="/dashboard">Dashboard</NavLink>
          </li>
          <li className="nav__item">
            <NavLink to="/web/photos">Resources</NavLink>
          </li>
          <li className="nav__item">
            <NavLink to="/web/news">News</NavLink>
          </li>
          <li className="nav__item">
            <NavLink to="/web/contacts">Queries</NavLink>
          </li>
          <li className="nav__item">
            <NavLink className="nav-item nav-link" to="/logout">
              Logout
            </NavLink>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navigation;
// const Navigation = ({ user }) => {
//   const logoUrl = process.env.REACT_APP_URL + "/logo.png";
//   const spriteUrl = process.env.REACT_APP_URL + "/sprite.svg#menu";

//   return (
//     <nav className="nav collapsible">
//       <a className="nav__brand" href="/dashboard">
//         <img height="40" src={logoUrl} alt="" />
//       </a>
//       <svg className="icon icon--white nav__toggler">
//         <use xlinkHref={spriteUrl} />
//       </svg>
//       <ul className="list nav__list collapsible__content">
//         <li className="nav__item">
//           <NavLink to="/dashboard">Dashboard</NavLink>
//         </li>
//         <li className="nav__item">
//           <NavLink to="/web/photos">Resources</NavLink>
//         </li>
//         <li className="nav__item">
//           <NavLink to="/web/news">News</NavLink>
//         </li>
//         <li className="nav__item">
//           <NavLink to="/web/contacts">Queries</NavLink>
//         </li>
//         <li className="nav__item">
//           <NavLink className="nav-item nav-link" to="/logout">
//             Logout
//           </NavLink>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default Navigation;
