import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getSingle, updateSingle } from "../../services/newsService";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import TableTitle from "../common/tableTitle";
import { Table } from "semantic-ui-react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML, convertFromHTML } from "draft-convert";
import Section from "../common/section";

class TourEditForm extends Form {
  state = {
    preferredDate: new Date(),
    thumbUrl: "",
    imageUrl: "",

    data: {
      title: "",
      short: "",
      author: "",
    },
    editorState: EditorState.createEmpty(),
    errors: {},
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  schema = {
    title: Joi.string().required().label("Title"),
    short: Joi.string().required().label("Short"),
    author: Joi.string().required().label("Author"),
  };

  onChangeThumbnail = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.setState({ thumbUrl: files[0] });
    //this.createImage(files[0]);
  };

  onChangeImage = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.setState({ imageUrl: files[0] });
    //this.createImage(files[0]);
  };

  async populateAccount() {
    try {
      const accountId = this.props.match.params.id;

      this.setState({ blocking: true });
      const { data: account } = await getSingle(accountId);

      this.setState({ blocking: false });

      const dateData = this.mapingData(account);

      this.mapingImagesData(account);

      const plainText = account.description;
      //const content = ContentState.createFromText(plainText);

      const editorState = EditorState.createWithContent(
        convertFromHTML(plainText)
      );

      this.setState({
        thumbUrl: account.thumbnail,
        imageUrl: account.image,
        editorState: editorState,
        preferredDate:
          dateData.preferredDate != "01/01/1970" &&
          dateData.preferredDate != undefined &&
          dateData.preferredDate != ""
            ? new Date(dateData.preferredDate)
            : "",
      });

      this.setState({ data: this.mapToViewModel(account) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateAccount();
  }

  mapingData(account) {
    return {
      preferredDate: account.news_date,
    };
  }

  mapingImagesData(account) {
    return {
      thumbUrl: account.thumbnail,
      imageUrl: account.image,
    };
  }

  mapToViewModel(account) {
    return {
      title: account.title,
      short: account.short,
      author: account.author,
    };
  }

  doSubmit = async () => {
    try {
      const ledgerTypeId = this.props.match.params.id;

      const text = convertToHTML(this.state.editorState.getCurrentContent());

      let formData = new FormData();
      formData.append("title", this.state.data.title);
      formData.append("short", this.state.data.short);
      formData.append("description", text);
      formData.append("author", this.state.data.author);
      formData.append("thumbnail", this.state.thumbUrl);
      formData.append("image", this.state.imageUrl);

      var event = new Date(this.state.preferredDate);
      let date = JSON.stringify(event);
      date = date.slice(1, 11);
      formData.append("news_date", date);
      formData.append("_method", "PUT");

      await updateSingle(formData, ledgerTypeId);
      this.setState({ blocking: false });
      toast.success("News has been updated successfully.");
      this.props.history.push("/web/news");
    } catch (ex) {
      console.log(ex.response);
      if (ex.response && ex.response.status === 422) {
        const errors = { ...this.state.errors };

        if (ex.response.data.errors.name)
          errors.name = ex.response.data.errors.name;

        if (ex.response.data.errors.address)
          errors.address = ex.response.data.errors.address;

        if (ex.response.data.errors.phone)
          errors.phone = ex.response.data.errors.phone;

        if (ex.response.data.errors.account)
          errors.account = ex.response.data.errors.account;

        this.setState({ errors, blocking: false });
        // toast.warning("check validation errors.");
      }
    }
  };

  handleChangePreferredDate = (date) => {
    this.setState({
      preferredDate: date,
    });
  };

  render() {
    const dashboardIcon = process.env.REACT_APP_URL + "/new_theme/bank.png";
    const { editorState } = this.state;
    // if (auth.getCurrentUser().email != "company-unblocker@gondalgroup.ae") {
    //   return "Your are authorized to perform this action.";
    // }
    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <Section module="Update News" description="Update news" />

        <form onSubmit={this.handleSubmit} class="card">
          <div className="grid grid--8x2">
            <span>Author</span>
            {this.renderInput("author", "Author", "text")}
            <span>Title</span>
            {this.renderInput("title", "Title", "text")}
            <span>Short</span>
            {this.renderTextarea("short", "Short", "text")}
            <span>Date (dd/mm/year)</span>
            <DatePicker
              selected={this.state.preferredDate}
              onChange={this.handleChangePreferredDate}
            />

            <span>Thumbnail</span>
            <div>
              <input
                className="input_imagem_artigo"
                type="file"
                onChange={this.onChangeThumbnail}
              />
              <div>
                {this.state.thumbUrl ? (
                  <a
                    target="_blank"
                    href={
                      process.env.REACT_APP_BACKEND_URL +
                      "/storage/" +
                      this.state.thumbUrl
                    }
                    className="link-arrow"
                  >
                    Download
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <span>Image</span>
            <div>
              <input
                className="input_imagem_artigo"
                type="file"
                onChange={this.onChangeImage}
              />
              <div>
                {this.state.imageUrl ? (
                  <a
                    target="_blank"
                    href={
                      process.env.REACT_APP_BACKEND_URL +
                      "/storage/" +
                      this.state.imageUrl
                    }
                    className="link-arrow"
                  >
                    Download
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <span>Detail</span>
            <Editor
              initialEditorState={editorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              editorStyle={{
                border: "1px solid #eeeeee",
                padding: "7px",
                paddingTop: "0px",
              }}
              toolbarStyle={{}}
              editorState={editorState}
              onEditorStateChange={this.onEditorStateChange}
            />
            <div></div>
            <div className="grid__buttons">
              {this.renderButton("Save")}&nbsp;
              <Link to="/web/news" className="btn btn--secondary">
                Back
              </Link>
            </div>
          </div>
        </form>
      </BlockUi>
    );
  }
}

export default TourEditForm;
