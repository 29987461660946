import React, { Component } from "react";

class Section extends Component {
  render() {
    const { description, module } = this.props;
    return (
      <section className="block container block-plans search-block">
        <header class="block__header">
          <h2>{module}</h2>
          <p>{description}</p>
        </header>
      </section>
    );
  }
}

export default Section;
