import React, { Component } from "react";
import _ from "lodash";
import { Table } from "semantic-ui-react";

class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);
    let debitCredit = "";

    if (typeof item.room_ledger_type !== "undefined") {
      if (column.path === "payment") {
        debitCredit = " (" + item.room_ledger_type.name + ")";
        return _.get(item, column.path) + debitCredit;
      }
    }
    if (typeof item.vehicle_ledger_type !== "undefined") {
      if (column.path === "payment") {
        debitCredit = " (" + item.vehicle_ledger_type.name + ")";
        return _.get(item, column.path) + debitCredit;
      }
    }
    if (typeof item.visa_ledger_type !== "undefined") {
      if (column.path === "payment") {
        debitCredit = " (" + item.visa_ledger_type.name + ")";
        return _.get(item, column.path) + debitCredit;
      }
    }
    if (typeof item.yard_ledger_type !== "undefined") {
      if (column.path === "payment") {
        debitCredit = " (" + item.yard_ledger_type.name + ")";
        return _.get(item, column.path) + debitCredit;
      }
    }

    return _.get(item, column.path);
  };

  createKey = (item, column) => {
    return item.id + (column.path || column.key);
  };

  render() {
    const { data, columns } = this.props;
    return (
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            {columns.map((column) => (
              <td data-label={column.label} key={this.createKey(item, column)}>
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
